<template>
  <div class="kecheng">
    <kind-choose-view :show-kc="true" :type="12" :entrants-type="3" :test-paper-type="testPaperType" @isOk="isOk" />

    <!-- 学习资料 -->
    <div v-if="list && list.length > 0" class="quesTion-car">
      <div class="dis_flex_between">
        <div style=" width: 71%; border-radius: 8px">
          <!-- <div class="tab_box">
            <div class="btn_item" :class="[!search.type ? 'on' : '']" @click="changeTabs()">
              全部
            </div>
            <div v-for="(item, index) in search.classfiList" v-show="item.fName != '随堂练习'" :key="index" class="btn_item"
              :class="[search.type == item.fId ? 'on' : '']" @click="changeTabs(item.fId)">
              {{ item.fName }}
            </div>
          </div> -->
          <div v-for="(item, index) in list" :key="index" class="item page">
            <img style="width: 90px; height: 90px" class="item-img examPage" src="@/assets/img/partner/ziliao1.png" />
            <div class="item-content">
              <div style="align-items: flex-end" class="dis_flex_between">
                <div class="item-title elp">{{ item.title }}</div>
                <div class="item-count">
                  {{ item.clicks }} 浏览
                  <!-- {{ item.downloadCount }} 下载 -->
                </div>
              </div>
              <div class="item-but">
                <div class="item-tips dis_flex_start" style="align-items: center; height: 28px">
                  <i class="el-icon-document"></i>
                  <template v-if="!item.showPageNum">
                    <div style="margin-right: 10px">
                      {{ item.namesList.length > 0 ? item.namesList[0] : "" }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="namesList">
                      <div v-for="(items, indexs) in item.namesList" :key="indexs" class="elp">
                        {{ items }}
                      </div>
                    </div>
                  </template>

                  <span style="cursor: pointer" @click="showPage(item)">共<span style="color: #1d81f5">{{
                    item.number ? item.number : 0
                  }}</span>份</span>
                  <img style="
                      width: 9px;
                      height: 4px;
                      margin-left: 9px;
                      cursor: pointer;
                    " src="@/assets/img/partner/downarrow.png" @click="showPage(item)" />
                </div>
                <div v-if="(item.price > 0 && item.isPurchase == 1) || !userInfo" class="dis_flex_start"
                  style="align-items: center">
                  <!-- <div
              class="colleges-learn keep-check"
              @click="goOrder(item)"
            >
              立即购买
            </div> -->
                  <div v-if="university != 1" class="colleges-price" style="margin-right: 10px">
                    <span class="price-icon">￥</span>{{ item.price }}
                  </div>
                  <div class="btn" @click="toMaterialPage(item)">
                    下载资料包
                  </div>
                </div>
                <div v-else class="dis_flex_start" style="align-items: center">
                  <div class="colleges-price" style="margin-right: 21px">
                    <span class="price-icon">￥</span>{{ item.price }}
                  </div>
                  <div class="btn" @click="toMaterialPage(item)">
                    下载资料包
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background: #f5f5f5; padding: 10px">
            <el-pagination class="pagination partner-pagination" prev-text="上一页" next-text="下一页"
              layout="prev, pager, next" :total="total" :current-page="pageNum" :page-size="pageSize" :pager-count="5"
              @current-change="handleCurrentChange" @size-change="handleSizeChange" />
          </div>
        </div>

        <class-push :search="search" class="youlove">其他课程推荐</class-push>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";

import KindChooseView from "@/components/kindChooseViewzlSeventh.vue";
import classPush from "@/components/Know/classPushPartner.vue";
import { DataClient } from "@/api/dataClient";
const dataClient = new DataClient();
import { getInfo, getToken, getDomain } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();

export default {
  components: { KindChooseView, classPush },

  data() {
    return {
      list: {},
      search: {
        type: 0,
      },
      pageSize: 12,
      pageNum: 1,
      total: 0,
      showPageNum: false,
      userInfo: null,
      testPaperType: null,
      domainInfo: {},
      university: ''
    };
  },
  created() {
    this.domainInfo = getDomain()
    if (this.domainInfo.eId == process.env.VUE_APP_university) {
      this.university = 1
    }
    this.testPaperType = this.$route.query.type;
    this.userInfo = getInfo();
    // this.getList();
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      dataClient
        .getNewPackageList(
          this.pageSize,
          this.pageNum,
          this.search.kind,
          this.search.stageName,
          this.search.zyId,
          this.search.type,
          this.userInfo ? this.userInfo.id : undefined,
          this.search.kcId
        )
        .then((res) => {
          res.rows.forEach((e) => {
            e.showPageNum = false;
          });
          this.list = res.rows;
          this.total = res.total;
          if (res.code) {
            this.list = [];
            this.total = 0;
          }
        });
    },
    changeTabs(index) {
      if (index !== 0) {
        this.search.type = index;
      } else {
        this.search.type = null;
      }
      this.pageNum = 1;
      this.list = [];
      this.pageSize = 8;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    showPage(val) {
      console.log(val.showPageNum);
      this.$set(val, "showPageNum", !val.showPageNum);
    },
    // 筛选
    isOk(val) {
      console.log(val, "val");
      this.search = val;
      this.getList();
    },
    goOrder(item) {
      const token = getToken();
      if (token) {
        orderClient
          .addOrders(
            item.id,
            localStorage.getItem("userId"),
            item.title,
            5,
            item.title,
            item.kind
          )
          .then((res) => {
            if (res.code == 0) {
              /*   this.users = res.msg; */
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true);
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    // 点击图片跳转到详情页
    checkClick(item) {
      if (!this.userInfo) {
        this.$notify({
          title: "错误",
          message: "未登录，无法查看, 请先去登录",
          type: "error",
          duration: 2000,
        });
        return;
      }
      this.$router.push(`/data/dataPackageDetail?packageId=${item.id}`);
    },
    /* 点击免费试看 */
    async toMaterialPage(item) {
      if (this.userInfo) {
        await dataClient
          .getDocPackageDetails(item.id, this.userInfo.id)
          .then((res) => {
            this.detail = res.data;
          });
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: item.id,
            title: item.title,
            schoolId: item.schoolId,
            isPurchase: item.isPurchase,
            num: item.price,
            updateTime: item.updateTime,
            viewCount: item.viewCount,
            downloadCount: item.downloadCount,
          },
        });
      } else {
        this.$notify({
          title: "错误",
          message: "未登录，请去登录",
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tab_box {
  max-width: 412px;
  width: fit-content;
  height: 38px;
  border-radius: 6px;
  padding: 4px;
  background-color: #f5f7f9;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin: 10px;

  .btn_item {
    width: 132px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    color: #333333;
    position: relative;
    z-index: 1;
    cursor: pointer;
    margin-right: 2px;
  }

  .on {
    color: #4a6af0;
    font-weight: 800;
    background: #ffffff;
  }
}

.quesTion-car {
  width: 1200px;
  display: block;

  .item {
    width: 852px;
    height: 130px;
    margin-bottom: 0;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 30px;
    align-items: center;

    .item-title {
      height: 22px;
      max-width: 550px;
    }

    .item-count {
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #a5acb8;
    }

    .item-but {
      margin-top: 32px;

      .namesList {
        border: 1px solid #ddd;
        margin-right: 10px;
        z-index: 99;
        padding: 10px;
        border-radius: 8px;
        background-color: #fff;

        div {
          margin-bottom: 5px;
        }
      }
    }

    .colleges-price {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #fb2d25;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 94px;
      height: 36px;
      background: #ffffff;
      border-radius: 18px 18px 18px 18px;
      opacity: 1;
      border: 1px solid #cccccc;
      font-size: 14px;
      cursor: pointer;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }

  .item:last-child {
    border-bottom: 0;
  }
}

.price-icon {
  font-size: 14px;
}
</style>
